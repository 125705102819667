import {
  Box,
  Flex,
  LightMode,
  ListItem,
  Text,
  UnorderedList,
  Icon,
  Circle,
} from '@chakra-ui/react';
import { FiLock, FiCheck } from 'react-icons/fi';
import React from 'react';

interface PricingCardProps {
  bg: string;
  color: string;
  button: React.ReactElement & { props: { onClick?: () => void; isDisabled?: boolean } }
  data: {
    name: string;
    description: string;
    monthly: number | string;
    yearly: number | string;
    one_time: number | string;
    features: string[];
    selectedDuration: 'monthly' | 'yearly';
    tag?: string;
    locked?: boolean;
    first_month_price?: number;
    is_current_plan?: boolean;
  };
}

const BillingBadge = ({ text }: { text: string }) => (
  <Box
    rounded="full"
    fontSize="sm"
    bg="white"
    color="gray.900"
    px="3"
    py="1"
    pos="absolute"
    top="4"
    right="4"
    fontWeight="bold"
  >
    {text}
  </Box>
);

export const PricingCard = (props: PricingCardProps) => {
  const { bg, button, data, ...rest } = props;
  const {
    name,
    description,
    monthly,
    yearly,
    one_time,
    selectedDuration,
    features,
    tag,
    locked,
    first_month_price,
    is_current_plan,
  } = data;

  const isFree = Number(monthly) === 0;
  const isClickable = !locked && !button?.props?.isDisabled

  // Helper function to format prices
  const formatPrice = (price?: number | string): string => {
    if (price === undefined) return '';
    const numericPrice = Number(price);
    return numericPrice % 1 === 0
      ? `$${numericPrice}` // No cents
      : `$${numericPrice.toFixed(2)}`; // Include cents
  };

  const isOneTimePlan = one_time !== undefined; // Determine if it's a one-time plan

  const priceMap = isOneTimePlan
    ? formatPrice(one_time)
    : selectedDuration === 'monthly'
      ? first_month_price
        ? formatPrice(first_month_price)
        : formatPrice(monthly)
      : formatPrice(yearly);

  const periodText = isOneTimePlan
    ? 'one-time payment' // Updated text
    : selectedDuration === 'monthly'
      ? first_month_price
        ? 'first month'
        : 'per month'
      : 'per year';

  const handleCardClick = () => {
    if (isClickable && button.props.onClick) {
      button.props.onClick();
    }
  };

  return (
    <Flex
      direction="column"
      px="6"
      py="8"
      rounded="lg"
      pos="relative"
      w="full"
      maxW="350px"
      overflow="hidden"
      bg={bg}
      cursor={isClickable ? 'pointer' : 'not-allowed'}
      onClick={handleCardClick}
      border={is_current_plan ? '3px solid' : 'none'}
      borderColor={is_current_plan ? 'blue.200' : 'transparent'}
      transition="border 0.2s ease-in-out"
      {...rest}
    >
      {locked && (
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0, 0, 0, 0.8)"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          opacity="1"
          transition="opacity 0.3s ease"
          _hover={{ opacity: 0 }}
          zIndex="5"
        >
          <Icon as={FiLock} boxSize={12} color="white" />
          <Text
            fontSize="md"
            lineHeight="1"
            color="white"
            fontWeight="bold"
            textAlign="center"
            mt="3"
          >
            Selected languages exceed plan limit
          </Text>
        </Box>
      )}

      {/* Top-Right Selected Checkbox */}
      {is_current_plan && (
        <Circle
          size="24px"
          bg="blue.500"
          color="white"
          pos="absolute"
          top="3"
          right="3"
          border="2px solid white"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={FiCheck} boxSize={4} />
        </Circle>
      )}

      <Box flex="1">
        {tag && <BillingBadge text={tag} />}

        <Text fontSize="2xl" lineHeight="1" fontWeight="bold">
          {name}
        </Text>

        <Flex align="center" fontWeight="extrabold" mt="4" mb="3">
          <Text
            fontSize={{ base: '6xl', xl: '7xl' }}
            lineHeight="1"
            flexShrink={0}
          >
            {priceMap}
          </Text>
          {!isFree && (
            <>
              <Box mx="4">
                <Box
                  w="2px"
                  h="12"
                  bg={props.color}
                  opacity={0.5}
                  transform="rotate(15deg)"
                />
              </Box>
              <Text fontSize="xl" lineHeight="1.2">
                {periodText}
              </Text>
            </>
          )}
        </Flex>

        {selectedDuration === 'monthly' && first_month_price && !isOneTimePlan && (
          <Text
            fontSize={{ base: '1xl', xl: '1xl' }}
            fontWeight="bold"
            lineHeight="1"
            flexShrink={0}
          >
            then {formatPrice(monthly)} per month
          </Text>
        )}

        <Box mt="6">
          <Text fontSize="xl" fontWeight="semibold" mb="6">
            {description}
          </Text>
          <UnorderedList spacing="3">
            {features.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Box>

      {button && (
        <Box mt="10" zIndex="2">
          <LightMode>{button}</LightMode>
        </Box>
      )}
    </Flex>
  );
};
