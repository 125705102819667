import React from 'react';
import {
  Alert,
  AlertIcon,
  Flex,
  Text,
  Button,
  Box,
  Spinner,
  useColorModeValue,
  keyframes,
} from '@chakra-ui/react';

const waveKeyframes = keyframes`
  0% {
    transform: scaleX(1) scaleY(1);
    opacity: 0.3;
  }
  100% {
    transform: scaleX(1.4) scaleY(2);
    opacity: 0;
  }
`;

const NoticeAlert = ({
                       label,
                       description,
                       buttonText,
                       onClick,
                       isLoading,
                       pulse = false,
                     }) => {
  // Use consistent Chakra color modes
  const bgColor = useColorModeValue('blue.50', 'blue.900');
  const color = useColorModeValue('blue.800', 'blue.200');
  const iconColor = useColorModeValue('blue.600', 'blue.400');
  const waveColor = useColorModeValue('blue.300', 'blue.700');

  // Because we want the “wave” shape to match the button exactly,
  // store a common borderRadius. You can tweak or match Chakra defaults.
  const buttonBorderRadius = 'md';

  return (
    <Alert
      status="info"
      borderRadius="md"
      boxShadow="sm"
      bg={bgColor}
      color={color}
      px="6"
      py="4"
    >
      <Flex
        direction={['column', 'row']}
        align={['flex-start', 'center']}
        justify="space-between"
        w="full"
      >
        {/* Left Section: Icon + Text */}
        <Flex
          align="center"
          flex="1"
          minW="0"
          mr={['0', '4']}
          mb={['4', '0']}
        >
          <AlertIcon color={iconColor} mr="4" boxSize="4" />
          <Box>
            <Text fontWeight="bold">{label}</Text>
            {isLoading ? (
              <Flex align="center" mt="1">
                <Spinner size="sm" mr="2" />
                <Text fontSize="sm">Loading...</Text>
              </Flex>
            ) : (
              <Text fontSize="sm">{description}</Text>
            )}
          </Box>
        </Flex>

        {/* Right Section: Button with optional wave behind it */}
        <Box position="relative" display="inline-block">
          {pulse && (
            <Box
              // The wave container behind the button
              position="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              overflow="visible"
              zIndex={0}
            >
              {[0, 1, 2].map((waveIndex) => (
                <Box
                  key={waveIndex}
                  // Each wave is a rectangle with the same borderRadius
                  position="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  h="100%"
                  borderRadius={buttonBorderRadius}
                  bg={waveColor}
                  opacity={0}
                  // Expand from 1.0 => 1.4 scale
                  animation={`${waveKeyframes} 2s ease-out ${waveIndex * 0.9}s infinite`}
                />
              ))}
            </Box>
          )}

          <Button
            flexShrink={0}
            size="sm"
            colorScheme="blue"
            onClick={onClick}
            isLoading={isLoading}
            loadingText="Loading"
            position="relative"
            zIndex={1}
            borderRadius={buttonBorderRadius} // match wave shape
          >
            {buttonText}
          </Button>
        </Box>
      </Flex>
    </Alert>
  );
};

export default NoticeAlert;
