import React, { useEffect, useRef, useState } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import axiosInstance from 'utils/axiosInstance';

export const SupportWidget = () => {
  const [visitorData, setVisitorData] = useState(null);
  const [loadingVisitorData, setLoadingVisitorData] = useState(true);
  const tawkMessengerRef = useRef();

  useEffect(() => {
    const fetchVisitorData = async () => {
      try {
        const response = await axiosInstance.get('/support_chat/api/configurations');
        setVisitorData(response.data);
      } catch (error) {
        console.error('Failed to fetch visitor data:', error);
      } finally {
        setLoadingVisitorData(false);
      }
    };

    fetchVisitorData();
  }, []);

  const handleMessengerLoad = () => {
    if (visitorData && tawkMessengerRef.current) {
      tawkMessengerRef.current.setAttributes(
        { name: visitorData.username, email: visitorData.email },
        (error) => {
          if (error) console.error('Failed to set attributes on TawkMessenger:', error);
        }
      );

      tawkMessengerRef.current.addTags(
        [visitorData.wix_id, visitorData.app_name],
        (error) => {
          if (error) console.error('Failed to add tags on TawkMessenger:', error);
        }
      );
    }
  };

  if (!process.env.REACT_APP_TAWK_TO_PROPERTY_ID || loadingVisitorData) {
    return null; // Do not render the widget if loading or missing credentials
  }

  return (
    <TawkMessengerReact
      propertyId={process.env.REACT_APP_TAWK_TO_PROPERTY_ID}
      widgetId={process.env.REACT_APP_TAWK_TO_WIDGET_ID}
      ref={tawkMessengerRef}
      onLoad={handleMessengerLoad}
    />
  );
};
