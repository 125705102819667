import React from 'react';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { BsCloudArrowUp } from "react-icons/bs";

const CreateProductCard = ({ onClick, hoverBg = 'gray.100', vendor= 'wix' }) => {
  return (
    <Flex
      align="center"
      w="full"
      p="3"
      cursor="pointer"
      minW="280px"
      _hover={{ bg: hoverBg }}
      onClick={onClick}
    >
      <Box width="50px">
        <Icon as={BsCloudArrowUp} boxSize="6" mr="3" color="gray.500" />
      </Box>
      <Box>
        <Text fontWeight="bold">{ "New Product" }</Text>
        <Text fontSize="sm" color="gray.500">{ "Created after sync is triggered" }</Text>
      </Box>
    </Flex>
  );
};

export default CreateProductCard;
